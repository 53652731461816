<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="sm:flex sm:items-center py-5">
    <!-- <div class="sm:flex-auto">
      <h1 class="text-xl font-semibold text-gray-900">Orders</h1>
      <button
        class="btn btn-sm bg-orange-400 rounded-sm p-1 m-1"
        @click="playSound()"
      >
        Test Alert
      </button>
      <button
        class="btn btn-sm bg-orange-400 rounded-sm p-1 m-1"
        @click="testSpeech()"
      >
        Test Read Order
      </button>
      <p class="mt-2 text-sm text-gray-700">Live orders</p>
    </div> -->
    <!-- <div class="" v-if="voiceList.length">
      <label for="voices">Select a voice</label>
      <select class="form-control" id="voices" v-model="selectedVoice">
        <option
          v-for="(voice, index) in voiceList"
          :data-lang="voice.lang"
          :key="index"
          :value="index"
        >
          {{ voice.name }} ({{ voice.lang }})
        </option>
      </select>
    </div> -->
    <div
      class="
        sm:grid
        sm:grid-cols-3
        sm:gap-4
        sm:items-start
        sm:border-gray-200
        sm:pt-5
        sm:m-5
      "
    >
      <label
        for="vendorId"
        class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        Vendor
      </label>
      <div class="mt-1 sm:mt-0 sm:col-span-2">
        <!-- {{events.data}} -->
        <select
          @change="getOrders()"
          id="vendorId"
          name="vendorId"
          v-model="vendor_id"
          autocomplete="vendorId-name"
          class="
            max-w-lg
            mt-0
            block
            focus:ring-green-500 focus:border-green-500
            w-full
            shadow-sm
            sm:max-w-xs sm:text-sm
            border-gray-300
            rounded-md
          "
        >
          <option
            v-for="vendor in vendors.data"
            :key="vendor.id"
            :value="vendor.id"
          >
            {{ vendor.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
      <button
        type="button"
        class="
          inline-flex
          items-center
          justify-center
          rounded-md
          border border-transparent
          bg-green-600
          px-4
          py-2
          text-sm
          font-medium
          text-white
          shadow-sm
          hover:bg-green-700
          focus:outline-none
          focus:ring-2
          focus:ring-green-500
          focus:ring-offset-2
          sm:w-auto
        "
        @click="$router.push('/orders')"
      >
        Full Orders View
      </button>
    </div>
    <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
      <button
        type="button"
        class="
          inline-flex
          items-center
          justify-center
          rounded-md
          border border-transparent
          bg-green-600
          px-4
          py-2
          text-sm
          font-medium
          text-white
          shadow-sm
          hover:bg-green-700
          focus:outline-none
          focus:ring-2
          focus:ring-green-500
          focus:ring-offset-2
          sm:w-auto
        "
        @click="$router.push('/dashboard/orders-list')"
      >
        Compact View
      </button>
    </div>
    <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
      <button
        type="button"
        class="
          inline-flex
          items-center
          justify-center
          rounded-md
          border border-transparent
          bg-green-600
          px-4
          py-2
          text-sm
          font-medium
          text-white
          shadow-sm
          hover:bg-green-700
          focus:outline-none
          focus:ring-2
          focus:ring-green-500
          focus:ring-offset-2
          sm:w-auto
        "
        @click="$router.push('/dashboard/order-history')"
      >
        Order History
      </button>
    </div>
  </div>
  <hr class="py-5" />
  <div class="grid grid-flow-col">
    <div class="bg-red-600 p-5">
      <p class="p-5 text-white text-bold font-bold text-lg">Orders</p>
      <ul role="list" class="grid grid-cols-1 gap-6">
        <!-- sm:grid-cols-2 lg:grid-cols-3 -->
        <li
          v-for="order in paidOrders"
          :key="order.email"
          class="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200"
        >
          <template
            v-if="order.order_status.id != 6 && order.order_status.id != 1"
          >
            <button
              class="
                inline-flex
                items-center
                justify-center
                rounded-md
                border border-transparent
                bg-green-600
                px-4
                py-2
                m-2
                text-sm
                font-medium
                text-white
                shadow-sm
                hover:bg-green-700
                focus:outline-none
                focus:ring-2
                focus:ring-green-500
                focus:ring-offset-2
                sm:w-auto
              "
              v-print="'#printOrder-' + order.id"
            >
              Print
            </button>
            <div class="py-5 px-5 text-bold capitalize">
              {{ order.order_status.status }}
            </div>
            <div :id="'printOrder-' + order.id">
            <div
              class="w-full flex items-center justify-between p-6 space-x-6"
            >
              <div class="flex-1 truncate">
                <div class="flex items-center space-x-3">
                  <!-- <h3
                    class="
                      text-gray-900 text-sm
                      font-medium
                      truncate
                      capitalize
                    "
                  >
                    {{ order.user.name + " " + order.user.surname }}
                  </h3> -->
                  <span
                    class="
                      flex-shrink-0
                      inline-block
                      px-2
                      py-0.5
                      text-green-800 text-xs
                      font-medium
                      bg-green-100
                      rounded-full
                    "
                    >{{ order.location.name }}</span
                  >
                </div>
                  <p class="mt-1 text-gray-500 text-sm truncate" v-if="order.multi_order != null && order.multi_order != '' ">
                {{ 'Multi Order id: ' +  $filters.substr(order.multi_order.uuid, 0, 8) }}
              </p>
                  <p class="mt-1 text-gray-500 text-sm truncate">
                {{ 'Order id: ' +  $filters.substr(order.uuid, 0, 8) }}
              </p>
              <p class="mt-1 text-gray-500 text-sm truncate" v-if="order.user">
                {{ 'User ' +  order.user_id+ ': ' + order.user.name }}
              </p>
              <p class="mt-1 text-gray-500 text-sm truncate" v-if="order.user_location !=  null">
                {{ 'Table Number: ' +  order.user_location.table_number }}
              </p>
              <p class="mt-1 text-gray-500 text-sm truncate" v-else>
                {{ 'Table Number: No table' }}
              </p>
                <p class="mt-1 text-gray-500 text-sm truncate">
                  {{ getDate(order.created_at) }}
                </p>
              </div>
              <img
                class="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
                :src="'https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png'"
                alt=""
              />
            </div>
            <p
              v-for="item in order.order_items"
              :key="item.id"
              class="
                px-3
                my-1
                text-gray-500 text-sm
                pt-2
                capitalize
                font-bold
                truncate
              "
            >
              <span v-if="item.item != '' && item.item != null">{{
                item.item.name + " * " + item.qty
              }}</span>
            </p>
</div>
            <div>
              <div class="-mt-px flex divide-x divide-gray-200">
                <div class="w-0 flex-1 flex" v-if="order.order_status.id == 2">
                  <a
                    :href="'#'"
                    @click="updateOrder(order, 3)"
                    class="
                      relative
                      -mr-px
                      w-0
                      flex-1
                      inline-flex
                      items-center
                      justify-center
                      py-4
                      text-sm text-green-800
                      font-medium
                      border border-transparent
                      rounded-bl-lg
                      hover:text-gray-500
                    "
                  >
                    <CheckIcon
                      class="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span
                      class="
                        ml-3
                        inline-flex
                        items-center
                        justify-center
                        rounded-md
                        border border-transparent
                        bg-green-600
                        px-4
                        py-2
                        text-sm
                        font-medium
                        text-white
                        shadow-sm
                        hover:bg-green-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-green-500
                        focus:ring-offset-2
                        sm:w-auto
                      "
                      >Queue</span
                    >
                  </a>
                </div>
                <div class="w-0 flex-1 flex" v-if="order.order_status.id == 3">
                  <a
                    :href="'#'"
                    @click="updateOrder(order, 4)"
                    class="
                      relative
                      -mr-px
                      w-0
                      flex-1
                      inline-flex
                      items-center
                      text-green-800
                      justify-center
                      py-4
                      text-sm text-green-800
                      font-medium
                      border border-transparent
                      rounded-bl-lg
                      hover:text-gray-500
                    "
                  >
                    <CheckIcon
                      class="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span
                      class="
                        ml-3
                        inline-flex
                        items-center
                        justify-center
                        rounded-md
                        border border-transparent
                        bg-green-600
                        px-4
                        py-2
                        text-sm
                        font-medium
                        text-white
                        shadow-sm
                        hover:bg-green-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-green-500
                        focus:ring-offset-2
                        sm:w-auto
                      "
                      >Prepare</span
                    >
                  </a>
                </div>
              </div>
            </div>
          </template>
        </li>
      </ul>
    </div>

    <div class="bg-yellow-600 p-5">
      <p class="p-5 text-white text-bold font-bold text-lg">Preparing</p>
      <ul role="list" class="grid grid-cols-1 gap-6">
        <!-- sm:grid-cols-2 lg:grid-cols-3 -->
        <li
          v-for="order in prepareOrders"
          :key="order.email"
          class="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200"
        >
          <template
            v-if="order.order_status.id != 6 && order.order_status.id != 1"
          >
            <div class="py-5 px-5 text-bold capitalize">
              {{ order.order_status.status }}
            </div>
            <div class="w-full flex items-center justify-between p-6 space-x-6">
              <div class="flex-1 truncate">
                <div class="flex items-center space-x-3">
                  <!-- <h3
                    class="
                      text-gray-900 text-sm
                      font-medium
                      truncate
                      capitalize
                    "
                  >
                    {{ order.user.name + " " + order.user.surname }}
                  </h3> -->
                  <span
                    class="
                      flex-shrink-0
                      inline-block
                      px-2
                      py-0.5
                      text-green-800 text-xs
                      font-medium
                      bg-green-100
                      rounded-full
                    "
                    >{{ order.location.name }}</span
                  >
                </div>
              <p class="mt-1 text-gray-500 text-sm truncate" v-if="order.multi_order != null && order.multi_order != '' ">
                {{ 'Multi Order id: ' +  $filters.substr(order.multi_order.uuid, 0, 8) }}
              </p>
                   <p class="mt-1 text-gray-500 text-sm truncate">
                {{ 'Order id: ' +  $filters.substr(order.uuid, 0, 8) }}
              </p>
              <p class="mt-1 text-gray-500 text-sm truncate" v-if="order.user">
                {{ 'User ' +  order.user_id+ ': ' + order.user.name }}
              </p>
              <p class="mt-1 text-gray-500 text-sm truncate" v-if="order.user_location !=  null">
                {{ 'Table Number: ' +  order.user_location.table_number }}
              </p>
              <p class="mt-1 text-gray-500 text-sm truncate" v-else>
                {{ 'Table Number: No table' }}
              </p>
                <p class="mt-1 text-gray-500 text-sm truncate">
                  {{ getDate(order.created_at) }}
                </p>
              </div>
              <img
                class="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
                :src="'https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png'"
                alt=""
              />
            </div>
            <p
              v-for="item in order.order_items"
              :key="item.id"
              class="
                px-3
                my-1
                text-gray-500 text-sm
                pt-2
                capitalize
                font-bold
                truncate
              "
            >
              <span v-if="item.item != '' && item.item != null">
                {{ item.item.name + " * " + item.qty }}
              </span>
            </p>

            <div>
              <div class="-mt-px flex divide-x divide-gray-200" v-if="order.order_status.id == 4">
                <div class="w-0 flex-1 flex" >
                  <a
                    :href="'#'"
                    @click="updateOrder(order, 5)"
                    class="
                      relative
                      -mr-px
                      w-0
                      flex-1
                      inline-flex
                      items-center
                      justify-center
                      py-4
                      text-sm text-green-800
                      font-medium
                      border border-transparent
                      rounded-bl-lg
                      hover:text-gray-500
                    "
                  >
                    <CheckIcon
                      class="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span
                      class="
                        ml-3
                        inline-flex
                        items-center
                        justify-center
                        rounded-md
                        border border-transparent
                        bg-green-600
                        px-4
                        py-2
                        text-sm
                        font-medium
                        text-white
                        shadow-sm
                        hover:bg-green-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-green-500
                        focus:ring-offset-2
                        sm:w-auto
                      "
                      >Notify Customer to Collect</span
                    >
                  </a>
                </div>
                <div class="w-0 flex-1 flex" v-if="order.order_status.id == 5">
                  <a
                    :href="'#'"
                    @click="updateOrder(order, 6)"
                    class="
                      relative
                      -mr-px
                      w-0
                      flex-1
                      inline-flex
                      items-center
                      justify-center
                      py-4
                      text-sm text-green-800
                      font-medium
                      border border-transparent
                      rounded-bl-lg
                      hover:text-gray-500
                    "
                  >
                    <CheckIcon
                      class="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span
                      class="
                        ml-3
                        inline-flex
                        items-center
                        justify-center
                        rounded-md
                        border border-transparent
                        bg-green-600
                        px-4
                        py-2
                        text-sm
                        font-medium
                        text-white
                        shadow-sm
                        hover:bg-green-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-green-500
                        focus:ring-offset-2
                        sm:w-auto
                      "
                      >Mark Collected</span
                    >
                  </a>
                </div>
              </div>
            </div>
          </template>
        </li>
      </ul>
    </div>

    <div class="bg-blue-600 p-5">
      <p class="p-5 text-white text-bold font-bold text-lg">Collections</p>
      <ul role="list" class="grid grid-cols-1 gap-6">
        <!-- sm:grid-cols-2 lg:grid-cols-3 -->
        <li
        v-for="order in collectionOrders"
        :key="order.email"
        class="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200"
        >
        <template
        v-if="order.order_status.id != 6 && order.order_status.id != 1"
        >
        <button @click="announceCollection($filters.substr(order.uuid, 0, 8))" class=" inline-flex
                items-center
                justify-center
                rounded-md
                border border-transparent
                bg-green-600
                px-4
                py-2
                m-2
                text-sm
                font-medium
                text-white
                shadow-sm
                hover:bg-green-700
                focus:outline-none
                focus:ring-2
                focus:ring-green-500
                focus:ring-offset-2
                sm:w-auto">Announce</button>
            <div class="py-5 px-5 text-bold capitalize">
              {{ order.order_status.status }}
            </div>
            <div class="w-full flex items-center justify-between p-6 space-x-6">
              <div class="flex-1 truncate">
                <div class="flex items-center space-x-3">
                  <!-- <h3
                    class="
                      text-gray-900 text-sm
                      font-medium
                      truncate
                      capitalize
                    "
                  >
                    {{ order.user.name + " " + order.user.surname }}
                  </h3> -->
                  <span
                    class="
                      flex-shrink-0
                      inline-block
                      px-2
                      py-0.5
                      text-green-800 text-xs
                      font-medium
                      bg-green-100
                      rounded-full
                    "
                    >{{ order.location.name }}</span
                  >
                </div>
                  <p class="mt-1 text-gray-500 text-sm truncate" v-if="order.multi_order != null && order.multi_order != '' ">
                {{ 'Multi Order id: ' +  $filters.substr(order.multi_order.uuid, 0, 8) }}
              </p>
                     <p class="mt-1 text-gray-500 text-sm truncate">
                {{ 'Order id: ' +  $filters.substr(order.uuid, 0, 8) }}
              </p>
              <p class="mt-1 text-gray-500 text-sm truncate" v-if="order.user">
                {{ 'User ' +  order.user_id+ ': ' + order.user.name }}
              </p>
              <p class="mt-1 text-gray-500 text-sm truncate" v-if="order.user_location !=  null">
                {{ 'Table Number: ' +  order.user_location.table_number }}
              </p>
              <p class="mt-1 text-gray-500 text-sm truncate" v-else>
                {{ 'Table Number: No table' }}
              </p>
                <p class="mt-1 text-gray-500 text-sm truncate">
                  {{ getDate(order.created_at) }}
                </p>
              </div>
              <img
                class="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
                :src="'https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png'"
                alt=""
              />
            </div>
            <p
              v-for="item in order.order_items"
              :key="item.id"
              class="
                px-3
                my-1
                text-gray-500 text-sm
                pt-2
                capitalize
                font-bold
                truncate
              "
            >
              <span v-if="item.item != '' && item.item != null">
                {{ item.item.name + " * " + item.qty }}
              </span>
            </p>

            <div>
              <div class="-mt-px flex divide-x divide-gray-200">
                <div class="w-0 flex-1 flex" v-if="order.order_status.id == 4">
                  <a
                    :href="'#'"
                    @click="updateOrder(order, 5)"
                    class="
                      relative
                      -mr-px
                      w-0
                      flex-1
                      inline-flex
                      items-center
                      justify-center
                      py-4
                      text-sm text-green-800
                      font-medium
                      border border-transparent
                      rounded-bl-lg
                      hover:text-gray-500
                    "
                  >
                    <CheckIcon
                      class="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span
                      class="
                        ml-3
                        inline-flex
                        items-center
                        justify-center
                        rounded-md
                        border border-transparent
                        bg-green-600
                        px-4
                        py-2
                        text-sm
                        font-medium
                        text-white
                        shadow-sm
                        hover:bg-green-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-green-500
                        focus:ring-offset-2
                        sm:w-auto
                      "
                      >Notify Customer to Collect</span
                    >
                  </a>
                </div>
                <div class="w-0 flex-1 flex" v-if="order.order_status.id == 5">
                  <a
                    :href="'#'"
                    @click="updateOrder(order, 6)"
                    class="
                      relative
                      -mr-px
                      w-0
                      flex-1
                      inline-flex
                      items-center
                      justify-center
                      py-4
                      text-sm text-green-800
                      font-medium
                      border border-transparent
                      rounded-bl-lg
                      hover:text-gray-500
                    "
                  >
                    <CheckIcon
                      class="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span
                      class="
                        ml-3
                        inline-flex
                        items-center
                        justify-center
                        rounded-md
                        border border-transparent
                        bg-green-600
                        px-4
                        py-2
                        text-sm
                        font-medium
                        text-white
                        shadow-sm
                        hover:bg-green-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-green-500
                        focus:ring-offset-2
                        sm:w-auto
                      "
                      >Mark Collected</span
                    >
                  </a>
                </div>
              </div>
            </div>
          </template>
        </li>
      </ul>
    </div>

    <div class="bg-green-600 p-5">
      <p class="p-5 text-white text-bold font-bold text-lg">Completed</p>
      <ul role="list" class="grid grid-cols-1 gap-6">
        <!-- sm:grid-cols-2 lg:grid-cols-3 -->
        <li
          v-for="order in completedOrders"
          :key="order.email"
          class="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200"
        >
          <div class="py-5 px-5 text-bold capitalize">
            {{ order.order_status.status }}
          </div>
          <div class="w-full flex items-center justify-between p-6 space-x-6">
            <div class="flex-1 truncate">
              <div class="flex items-center space-x-3">
                <!-- <h3
                  class="text-gray-900 text-sm font-medium truncate capitalize"
                >
                  {{ order.user.name + " " + order.user.surname }}
                </h3> -->
                <span
                  class="
                    flex-shrink-0
                    inline-block
                    px-2
                    py-0.5
                    text-green-800 text-xs
                    font-medium
                    bg-green-100
                    rounded-full
                  "
                  >{{order.location != null ? order.location.name : '' }}</span
                >
              </div>
                <p class="mt-1 text-gray-500 text-sm truncate" v-if="order.multi_order != null && order.multi_order != '' ">
                {{ 'Multi Order id: ' +  $filters.substr(order.multi_order.uuid, 0, 8) }}
              </p>
              <p class="mt-1 text-gray-500 text-sm truncate">
                {{ 'Order id: ' +  $filters.substr(order.uuid, 0, 8) }}
              </p>
              <p class="mt-1 text-gray-500 text-sm truncate" v-if="order.user">
                {{ 'User ' +  order.user_id+ ': ' + order.user.name }}
              </p>
              <p class="mt-1 text-gray-500 text-sm truncate" v-if="order.user_location !=  null">
                {{ 'Table Number: ' +  order.user_location.table_number }}
              </p>
              <p class="mt-1 text-gray-500 text-sm truncate" v-else>
                {{ 'Table Number: No table' }}
              </p>

              <!-- <p class="mt-1 text-gray-900 text-sm truncate">
                {{ "Order id: " +  order.id }}
              </p> -->
              <p class="mt-1 text-gray-500 text-sm truncate">
                {{ getDate(order.created_at) }}
              </p>
            </div>
            <img
              class="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
              :src="'https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png'"
              alt=""
            />
          </div>
          <p
            v-for="item in order.order_items"
            :key="item.id"
            class="
              px-3
              my-1
              text-gray-500 text-sm
              pt-2
              capitalize
              font-bold
              truncate
            "
          >
            <span v-if="item.item != '' && item.item != null">
              {{ item.item.name + " * " + item.qty }}</span
            >
          </p>

          <!-- <div>
            <div class="-mt-px flex divide-x divide-gray-200">
              <div class="w-0 flex-1 flex" v-if="order.order_status.id == 6">
                <a
                  :href="'#'"
                  @click="updateOrder(order, 6)"
                  class="
                    relative
                    -mr-px
                    w-0
                    flex-1
                    inline-flex
                    items-center
                    justify-center
                    py-4
                    text-sm text-green-800
                    font-medium
                    border border-transparent
                    rounded-bl-lg
                    hover:text-gray-500
                  "
                >
                  <CheckIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
                  <span
                    class="
                      ml-3
                      inline-flex
                      items-center
                      justify-center
                      rounded-md
                      border border-transparent
                      bg-green-600
                      px-4
                      py-2
                      text-sm
                      font-medium
                      text-white
                      shadow-sm
                      hover:bg-green-700
                      focus:outline-none
                      focus:ring-2
                      focus:ring-green-500
                      focus:ring-offset-2
                      sm:w-auto
                    "
                    >Mark Collected</span
                  >
                </a>
              </div>
            </div>
          </div> -->
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { CheckIcon } from '@heroicons/vue/24/outline'
import moment from 'moment'
export default {
  beforeCreate () {},
  created () {
    // const channel = this.$pusher.subscribe('dashboard')
    // channel.bind('user.log', ({ log }) => {
    //   console.log(`User ${log.user.name} has ${log.action} at ${log.time}`)
    // })
  },
  components: {
    CheckIcon
  },
  mounted () {
    this.getOrders(this.vendor_id)
    console.log('subscribing to `my-channel`...', {
      $pusher: this.$pusher
    })

    const channel = this.$pusher.subscribe('orders-channel')

    channel.bind('pusher:subscription_succeeded', () =>
      console.log('subscription succeeded')
    )

    channel.bind('new-order', (event) => {
      console.log('new-order', event)
      this.events.push(event)
      this.playSound()
      this.$toast.show(event.message, {
        position: 'top-right'
      })
      this.getOrders()
      this.newOrderSound()
    })
    this.getVendors()

    this.voiceList = this.synth.getVoices()
    if (this.voiceList.length) {
      this.isLoading = false
    }
    this.synth.onvoiceschanged = () => {
      this.voiceList = this.synth.getVoices()

      setTimeout(() => {
        this.isLoading = false
      }, 800)
    }
    this.listenForSpeechEvents()
  },
  unmounted () {
    console.log('unsubscribing...')
    this.$pusher.unsubscribe('orders-channel')
  },
  data: () => ({
    orders: [],
    paidOrders: [],
    prepareOrders: [],
    collectionOrders: [],
    completedOrders: [],
    issub: false,
    events: [],
    vendors: {},
    vendor_id: 0,
    synth: window.speechSynthesis,
    voiceList: [],
    isLoading: true,
    selectedVoice: 0,
    greetingSpeech: new window.SpeechSynthesisUtterance()
  }),
  methods: {
    getDate: (date) => {
      return moment(date).format('h:mm | ddd, d MMM')
    },
    getOrders () {
      this.axios
        .get('api-company/order/location/' + this.vendor_id)
        .then((res) => {
          this.orders = res.data
          console.log(this.orders.data[0])
          this.paidOrders = this.orders.data.filter(
            (order) =>
              order.order_status.id === 2 || order.order_status.id === 3
          )
          this.prepareOrders = this.orders.data.filter(
            (order) => order.order_status.id === 4
          )
          this.collectionOrders = this.orders.data.filter(
            (order) => order.order_status.id === 5
          )
          this.completedOrders = this.orders.data.filter(
            (order) => order.order_status.id === 6
          )
        })
    },
    playSound () {
      const audio = new Audio(require('@/assets/sounds/ding.mp3'))
      // const audio = new Audio(
      //   'http://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3'
      // )
      audio.play()
    },
    newOrderSound () {
      this.greetingSpeech.text = 'New Order'
      this.greetingSpeech.voice = this.voiceList[this.selectedVoice]
      this.synth.speak(this.greetingSpeech)
    },
    announceCollection (orderNumber) {
      this.greetingSpeech.text = 'Please collect your order number ' + orderNumber
      this.greetingSpeech.voice = this.voiceList[this.selectedVoice]
      this.greetingSpeech.rate = 0.8
      this.synth.speak(this.greetingSpeech)
    },
    reloadOrders () {
      console.log(this.vendor_id)
    },
    updateOrder (order, status) {
      this.axios
        .patch('api-company/order/' + order.id, { status_id: status })
        .then((res) => {
          this.orders = res.data
          this.getOrders()
          if (status === 5) {
            this.collectOrderSound(order)
          }
        })
    },
    getVendors () {
      this.axios.get('api-company/location').then((res) => {
        this.vendors = res.data
      })
    },
    listenForSpeechEvents () {
      this.greetingSpeech.onstart = () => {
        this.isLoading = true
      }
      this.greetingSpeech.onend = () => {
        this.isLoading = false
      }
    },
    collectOrderSound (order) {
      this.greetingSpeech.text = `Attention. Please collect your order number ${order.id}`
      this.greetingSpeech.voice = this.voiceList[this.selectedVoice]
      this.synth.speak(this.greetingSpeech)
    },
    testSpeech () {
      this.greetingSpeech.text =
        'Hello, Peter, Please collect your order number 55'
      this.greetingSpeech.voice = this.voiceList[this.selectedVoice]
      this.synth.speak(this.greetingSpeech)
    }
  }
}
</script>
