<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="px-4 sm:px-6 lg:px-8 text-left">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">Tables</h1>
        <p class="mt-2 text-sm text-gray-700">
          A list of all the tables per vendor.
        </p>
      </div>
      <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <button
          type="button"
          class="
            inline-flex
            items-center
            justify-center
            rounded-md
            border border-transparent
            bg-green-600
            px-4
            mx-2
            py-2
            text-sm
            font-medium
            text-white
            shadow-sm
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-green-500
            focus:ring-offset-2
            sm:w-auto
          "
          @click="$router.push('qr-code-list')"
        >
          Print QR Codes
        </button>
        <button
          type="button"
          class="
            inline-flex
            items-center
            justify-center
            rounded-md
            border border-transparent
            bg-green-600
            px-4
            py-2
            text-sm
            font-medium
            text-white
            shadow-sm
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-green-500
            focus:ring-offset-2
            sm:w-auto
          "
          @click="$router.push('table-add')"
        >
          Add Table
        </button>
        <button
          type="button"
          class="
            inline-flex
            items-center
            justify-center
            rounded-md
            border border-transparent
            bg-green-600
            px-4
            py-2
            ml-2
            text-sm
            font-medium
            text-white
            shadow-sm
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-green-500
            focus:ring-offset-2
            sm:w-auto
          "
          @click="$router.push('tables-add')"
        >
          Add Tables
        </button>
      </div>
    </div>
        <div
      class="
        sm:grid
        sm:grid-cols-3
        sm:gap-4
        sm:items-start
        sm:border-gray-200
        sm:pt-5
        sm:m-5
      "
    >
      <label
        for="vendorId"
        class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        Select a Vendor
      </label>
      <div class="mt-1 sm:mt-0 sm:col-span-2">
        <!-- {{events.data}} -->
        <select
          @change="getTables()"
          id="vendorId"
          name="vendorId"
          v-model="vendor_id"
          autocomplete="vendorId-name"
          class="
            max-w-lg
            mt-0
            block
            focus:ring-green-500 focus:border-green-500
            w-full
            shadow-sm
            sm:max-w-xs sm:text-sm
            border-gray-300
            rounded-md
          "
        >
          <option
            v-for="vendor in vendors.data"
            :key="vendor.id"
            :value="vendor.id"
          >
            {{ vendor.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="mt-8 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div
            class="
              overflow-hidden
              shadow
              ring-1 ring-black ring-opacity-5
              md:rounded-lg
            "
          >
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="
                      py-3.5
                      pl-4
                      pr-3
                      text-left text-sm
                      font-semibold
                      text-gray-900
                      sm:pl-6
                    "
                  >
                    ID
                  </th>
                  <th>Qr Code</th>
                  <th
                    scope="col"
                    class="
                      py-3.5
                      pl-4
                      pr-3
                      text-left text-sm
                      font-semibold
                      text-gray-900
                      sm:pl-6
                    "
                  >
                    Table Label
                  </th>
                  <th
                    scope="col"
                    class="
                      py-3.5
                      pl-4
                      pr-3
                      text-left text-sm
                      font-semibold
                      text-gray-900
                      sm:pl-6
                    "
                  >
                    Table Number
                  </th>

                  <th
                    scope="col"
                    class="
                      px-3
                      py-3.5
                      text-left text-sm
                      font-semibold
                      text-gray-900
                    "
                  >
                    Section
                  </th>
                  <th
                    scope="col"
                    class="
                      px-3
                      py-3.5
                      text-left text-sm
                      font-semibold
                      text-gray-900
                    "
                  >
                    Description
                  </th>
                  <th
                    scope="col"
                    class="
                      px-3
                      py-3.5
                      text-left text-sm
                      font-semibold
                      text-gray-900
                    "
                  >
                    Venue ID
                  </th>
                  <th
                    scope="col"
                    class="
                      px-3
                      py-3.5
                      text-left text-sm
                      font-semibold
                      text-gray-900
                    "
                  >
                    Created On
                  </th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span class="sr-only">View</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="table in tables" :key="table.id">
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                    <div class="flex items-center">
                      <div class="ml-4">
                        <div class="font-medium text-gray-900">
                          {{ table.id }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td @click="loadCode(table.location_id + '-' + table.table_number, 'Table')">
                    <qrcode-vue class="p-5" :value="table.location_id + '-' + table.table_number"></qrcode-vue>

                  </td>
                  <td>
                    <span class="text-center">{{table.location_id + '-' + table.table_number}}</span>
                  </td>
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                    <div class="flex items-center">
                      <div class="ml-4">
                        <div class="font-medium text-gray-900">
                          {{ table.table_number }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div class="text-gray-900">
                      {{ table.section }}
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div class="text-gray-900">
                      {{ table.description }}
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div class="text-gray-900">
                      {{ table.location_id }}
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{
                      moment(table.created_at).format(
                        "dddd, MMMM YYYY, h:mm:ss"
                      )
                    }}
                  </td>
                  <td
                    class="
                      relative
                      whitespace-nowrap
                      py-4
                      pl-3
                      pr-4
                      text-right text-sm
                      font-medium
                      sm:pr-6
                    "
                  >
                    <a
                      href="#"
                      class="text-red-600 hover:text-red-900 mx-3"
                      @click="deleteLocation(table.id)"
                      >Delete<span class="sr-only"
                        >, {{ table.name }}</span
                      ></a
                    >

                    <a
                      href="#"
                      @click="$router.push('table-edit/' + table.id)"
                      class="text-green-600 hover:text-green-900"
                      >Edit<span class="sr-only">, {{ table.name }}</span></a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import QrcodeVue from 'qrcode.vue'
export default {
  components: {
    QrcodeVue
  },
  created () {
    this.moment = moment
  },
  mounted () {
    this.getLocations()
    this.getTables()
  },
  data: () => ({
    vendors: [],
    tables: [],
    vendor_id: 0
  }),
  methods: {
    loadCode (code, name) {
      console.log(code, name)
      this.$router.push('qr-code-view/' + code + '/' + name)
    },
    getLocations () {
      this.axios.get('api-company/location').then((res) => {
        this.vendors = res.data
      })
    },
    getTables () {
      this.axios.get('api-company/table?location_id=' + this.vendor_id).then((res) => {
        this.tables = res.data.data
      })
    },
    deleteLocation (val) {
      this.axios.delete('api-company/table/' + val).then((res) => {
        this.$toast.show('Table has been deleted.', {
          position: 'top-right'
        })
        this.getLocations()
        this.getTables()
      })
    }
  }
}
</script>
