<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="px-4 sm:px-6 lg:px-8 text-left">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">Print QR codes</h1>
        <p class="mt-2 text-sm text-gray-700">
          A list of all the tables QR Codes
        </p>
      </div>
    </div>
    <div
      class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-5 sm:m-5"
    >
      <label
        for="vendorId"
        class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        Select a Vendor
      </label>
      <div class="mt-1 sm:mt-0 sm:col-span-2">
        <!-- {{events.data}} -->
        <select
          @change="getTables()"
          id="vendorId"
          name="vendorId"
          v-model="vendor_id"
          autocomplete="vendorId-name"
          class="max-w-lg mt-0 block focus:ring-green-500 focus:border-green-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
        >
          <option
            v-for="vendor in vendors.data"
            :key="vendor.id"
            :value="vendor.id"
          >
            {{ vendor.name }}
          </option>
        </select>
      </div>
      <div
          class="
            sm:grid
            sm:grid-cols-3
            sm:gap-4
            sm:items-start
            sm:border-t
            sm:border-gray-200
            sm:pt-5
          "
        >
          <label
            for="name"
            class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            QR Code Size
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">
            <input
              type="text"
              name="name"
              v-model="qrSize"
              id="name"
              autocomplete="given-name"
              class="
                max-w-lg
                block
                w-full
                shadow-sm
                focus:ring-green-500 focus:border-green-500
                sm:max-w-xs sm:text-sm
                border-gray-300
                rounded-md
              "
            />
          </div>
        </div>
    </div>
    <div class="mt-8 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div
            class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th class="p-5">Qr Codes</th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="table in tables" :key="table.id">
                  <td
                  class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                    @click="
                      loadCode(
                        table.location_id + '-' + table.table_number,
                        'Table'
                      )
                    "
                  >
                    <qrcode-vue
                      class="p-5"
                      :size="qrSize"
                      :value="table.location_id + '-' + table.table_number"
                    ></qrcode-vue>

                    <p>
                      <span class="text-center">
                        Table QR Code:
                        {{
                        table.location_id + "-" + table.table_number
                      }}</span>
                    </p>
                    <p>
                      Table Number: {{ table.table_number }}
                    </p>
                    <p>Table Venue: {{ table.location_id }}</p>
                    <p>Section: {{ table.section }}</p>
                    <p>Description: {{ table.description }}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import QrcodeVue from 'qrcode.vue'
export default {
  components: {
    QrcodeVue
  },
  created () {
    this.moment = moment
  },
  mounted () {
    this.getLocations()
    this.getTables()
  },
  data: () => ({
    vendors: [],
    tables: [],
    vendor_id: 0,
    qrSize: 400
  }),
  methods: {
    loadCode (code, name) {
      console.log(code, name)
      this.$router.push('qr-code-view/' + code + '/' + name)
    },
    getLocations () {
      this.axios.get('api-company/location').then((res) => {
        this.vendors = res.data
      })
    },
    getTables () {
      this.axios.get('api-company/table?location_id=' + this.vendor_id).then((res) => {
        this.tables = res.data.data
      })
    },
    deleteLocation (val) {
      this.axios.delete('api-company/table/' + val).then((res) => {
        this.$toast.show('Table has been deleted.', {
          position: 'top-right'
        })
        this.getLocations()
        this.getTables()
      })
    }
  }
}
</script>
