<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="text-center">
    <h3 class="mt-5 text-sm font-large text-gray-900">QR Code View</h3>
    <p class="mt-1 text-sm text-gray-500">Name: {{$route.params.name}}</p>
    <p class="mt-1 text-sm text-gray-500">QR Code: {{$route.params.code}}</p>
    <div class="mt-6">

      <button type="button" @click="$router.back" class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
        <RewindIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
        Go Back
      </button>
    </div>
    <div class="mt-6 content-center items-center inline-flex">
      <p v-if="showCode"><qrcode-vue class="p-5" :size="sizeqr" :value="$route.params.code"></qrcode-vue></p>

    </div>
    <br>
       <div class="mt-6 content-center items-center inline-flex">
     <p class="mt-1 text-lg text-center text-gray-500">QR Code: {{$route.params.code}}</p>

    </div>

  </div>
</template>

<script>
import { RewindIcon } from '@heroicons/vue/24/outline'

import QrcodeVue from 'qrcode.vue'
export default {
  data: () => ({
    showCode: false,
    sizeqr: 500
  }),
  name: 'QRcodeView',
  created () {
    this.showCode = true
  },
  components: {
    QrcodeVue,
    RewindIcon
  }
}
</script>
