<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="px-4 sm:px-6 lg:px-8 text-left">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">Edit a Menu</h1>
        <p class="mt-2 text-sm text-gray-700">
          Edit and save your menu
        </p>
      </div>
    </div>
     <div class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
        <div class="space-y-6 sm:space-y-5">
          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Name </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <input type="text" name="name" v-model="menu.name" id="name" autocomplete="given-name" class="max-w-lg block w-full shadow-sm focus:ring-green-500 focus:border-green-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>

          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="comapnyId" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Location </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <select id="comapnyId" name="comapnyId" v-model="menu.location_id" autocomplete="comapnyId-name" class="max-w-lg block focus:ring-green-500 focus:border-green-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
                <option v-for="location in locations" :key="location.id" :value="location.id">{{location.name}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <br>
            <div class="mt-4">
        <button
          type="button"
          class="
            inline-flex
            items-center
            justify-center
            rounded-md
            border border-transparent
            bg-green-600
            px-4
            py-2
            text-sm
            font-medium
            text-white
            shadow-sm
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-green-500
            focus:ring-offset-2
            sm:w-auto
          "
          @click="updateLocation()"
        >
          Update
        </button>
      </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  created () {
    this.moment = moment
  },
  mounted () {
    this.getLocations()
    this.getMenu()
  },
  data: () => ({
    menu: {
      name: '',
      location_id: 1
    },
    locations: []

  }),
  methods: {
    updateLocation () {
      this.axios.patch('api-company/menu/' + this.menu.id, this.menu).then((res) => {
        this.$toast.show('Location has been updated.', {
          position: 'top-right'
        })
        this.$router.push('/dashboard/menus-list')
      })
    },
    getMenu () {
      this.axios.get('api-company/menu/' + this.$route.params.id).then((res) => {
        this.menu = res.data
      }
      )
    },
    getLocations () {
      this.axios.get('api-company/location').then((res) => {
        this.locations = res.data.data
      }
      )
    }
  }
}
</script>
