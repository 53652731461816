import { createRouter, createWebHistory } from 'vue-router'

import Login from '../views/Auth/LoginView.vue'
import Register from '../views/Auth/RegisterView.vue'

import Dashboard from '../layouts/DashboardLayout.vue'

import ReportStats from '../views/Dashboard/Reports/Stats.vue'

import VendorsList from '../views/Dashboard/Vendors/VendorsList.vue'
import VendorsAdd from '../views/Dashboard/Vendors/VendorsAdd.vue'
import VendorsEdit from '../views/Dashboard/Vendors/VendorsEdit.vue'

import TableList from '../views/Dashboard/Tables/TableList.vue'
import QRCodeList from '../views/Dashboard/Tables/QRCodeList.vue'
import TableAdd from '../views/Dashboard/Tables/TableAdd.vue'
import TablesAdd from '../views/Dashboard/Tables/TablesAdd.vue'
import TableEdit from '../views/Dashboard/Tables/TableEdit.vue'

import MenusList from '../views/Dashboard/Menu/MenusList.vue'
import MenusAdd from '../views/Dashboard/Menu/MenusAdd.vue'
import MenusEdit from '../views/Dashboard/Menu/MenusEdit.vue'

import CategoriesEdit from '../views/Dashboard/Categories/CategoriesEdit.vue'
import CategoriesList from '../views/Dashboard/Categories/CategoriesList.vue'
import CategoriesAdd from '../views/Dashboard/Categories/CategoriesAdd.vue'

import ItemsList from '../views/Dashboard/Item/ItemsList.vue'
import ItemsAdd from '../views/Dashboard/Item/ItemsAdd.vue'
import ItemsEdit from '../views/Dashboard/Item/ItemsEdit.vue'

import OrdersList from '../views/Dashboard/Orders/OrdersList.vue'
import OrderHistory from '../views/Dashboard/Orders/OrderHistory.vue'

import PaymentList from '../views/Dashboard/Payments/PaymentsList.vue'
import SalesReport from '../views/Dashboard/Sales/SalesReport.vue'
import AllSalesComponent from '../components/sales/AllSalesComponent.vue'

import CompanyUserList from '../views/Dashboard/CompanyUsers/CompanyUserList.vue'

import PageNotFound from '../views/PageNotFound.vue'
import NotAuthorised from '../views/NotAuthorised.vue'
import QRCodeView from '../views/QRcodeView.vue'

const routes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      auth: true
    },
    children: [
      {
        path: '/',
        name: 'report-stats',
        component: ReportStats
      },

      {
        path: 'vendors-add',
        name: 'vendors-add',
        component: VendorsAdd
      },
      {
        path: 'vendors-list',
        name: 'vendors-list',
        component: VendorsList
      },
      {
        path: 'vendors-edit/:id',
        name: 'vendors-edit',
        component: VendorsEdit
      },

      {
        path: 'table-add',
        name: 'table-add',
        component: TableAdd
      },
      {
        path: 'tables-add',
        name: 'tables-add',
        component: TablesAdd
      },
      {
        path: 'table-list',
        name: 'table-list',
        component: TableList
      },
      {
        path: 'qr-code-list',
        name: 'qr-code-list',
        component: QRCodeList
      },
      {
        path: 'table-edit/:id',
        name: 'table-edit',
        component: TableEdit
      },

      {
        path: 'categories-add',
        name: 'categories-add',
        component: CategoriesAdd
      },
      {
        path: 'categories-list',
        name: 'categories-list',
        component: CategoriesList
      },
      {
        path: 'categories-edit/:id',
        name: 'categories-edit',
        component: CategoriesEdit
      },

      {
        path: 'menus-add',
        name: 'menus-add',
        component: MenusAdd
      },
      {
        path: 'menus-list',
        name: 'menus-list',
        component: MenusList
      },
      {
        path: 'menus-edit/:id',
        name: 'menus-edit',
        component: MenusEdit
      },

      {
        path: 'items-add',
        name: 'items-add',
        component: ItemsAdd
      },
      {
        path: 'items-list',
        name: 'items-list',
        component: ItemsList
      },
      {
        path: 'items-edit/:id',
        name: 'items-edit',
        component: ItemsEdit
      },

      {
        path: 'order-history',
        name: 'order-history',
        component: OrderHistory
      },
      {
        path: 'orders-list',
        name: 'orders-list',
        component: OrdersList
      },

      {
        path: 'payments-list',
        name: 'payments-list',
        component: PaymentList
      },
      {
        path: 'company-user-list',
        name: 'company-user-list',
        component: CompanyUserList
      },
      {
        path: 'qr-code-view/:code/:name',
        name: 'qr-code-view',
        component: QRCodeView
      },
      {
        path: 'sales',
        name: 'sales',
        component: SalesReport,
        children: [
          {
            path: 'all-sales',
            name: 'all-sales-component',
            component: AllSalesComponent
          },
          {
            path: 'multi-order',
            name: 'multi-order-component',
            component: AllSalesComponent
          }
        ]
      }

    ]
  },
  {
    path: '/orders',
    name: 'orders',
    component: OrdersList
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/not-authorised',
    name: 'not-authorised',
    component: NotAuthorised
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'page-not-found',
    component: PageNotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: 'active',
  routes
})

export default (app) => {
  app.router = router
  app.use(router)
}
