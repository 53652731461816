<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="px-4 sm:px-6 lg:px-8 text-left">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">Edit a Category</h1>
        <p class="mt-2 text-sm text-gray-700">
          Edit and save your category
        </p>
      </div>
    </div>
     <div class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
        <div class="space-y-6 sm:space-y-5">
          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Name </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <input type="text" name="name" v-model="category.name" id="name" autocomplete="given-name" class="max-w-lg block w-full shadow-sm focus:ring-green-500 focus:border-green-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>

          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="menuId" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Menu </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <select id="menuId" name="menuId" v-model="category.menu_id" autocomplete="menuId-name" class="max-w-lg block focus:ring-green-500 focus:border-green-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
                <option v-for="menu in menus" :key="menu.id" :value="menu.id">{{menu.name}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>

              <div
          class="
          sm:mt-5
            sm:grid
            sm:grid-cols-3
            sm:gap-4
            sm:items-start
            sm:border-t
            sm:border-gray-200
            sm:pt-5
          "
        >
        <file-pond
          name="test"
          ref="pond"
          label-idle="Drop files here..."
          v-bind:allow-multiple="true"
          accepted-file-types="image/jpeg, image/png"
          v-bind:files="myFiles"
          v-on:init="handleFilePondInit"
        />
          <div v-show="category.fullurl">
            <img :src="category.fullurl" class="rounded bg-green-300 p-5 mx-10 object-scale-down h-48 w-48" alt="">
          </div>
          <p v-if="!category.fullurl">No image uploaded for this category.</p>
        </div>
      <br>
            <div class="mt-4">
        <button
          type="button"
          class="
            inline-flex
            items-center
            justify-center
            rounded-md
            border border-transparent
            bg-green-600
            px-4
            py-2
            text-sm
            font-medium
            text-white
            shadow-sm
            hover:bg-green-700
            focus:outline-none
            focus:ring-2
            focus:ring-green-500
            focus:ring-offset-2
            sm:w-auto
          "
          @click="updateLocation()"
        >
          Update
        </button>
      </div>
  </div>
</template>

<script>
import moment from 'moment'
import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
)
export default {
  components: {
    FilePond
  },
  created () {
    this.moment = moment
  },
  mounted () {
    this.getCategory()
    this.getMenus()
  },
  data: () => ({
    myFiles: [],
    category: {
      name: '',
      menu_id: 1,
      image_url: '',
      fullUrl: ''
    },
    menus: [
      {
        id: 0,
        name: ''
      }
    ]

  }),
  methods: {
    handleFilePondInit: function () {
      // FilePond instance methods are available on `this.$refs.pond`
    },
    updateLocation () {
      const formData = new FormData()
      if (this.$refs.pond.getFiles().length) {
        formData.append('image', this.$refs.pond.getFiles()[0].file)
      }
      formData.append('_method', 'PUT')
      formData.append('menu_id', this.category.menu_id)
      formData.append('name', this.category.name)

      this.axios.post('api-company/category/' + this.category.id, formData).then((res) => {
        this.$toast.show('Location has been updated.', {
          position: 'top-right'
        })
        this.$router.push('/dashboard/categories-list')
      })
    },
    getCategory () {
      this.axios.get('api-company/category/' + this.$route.params.id).then((res) => {
        this.category = res.data
      }
      )
    },
    getMenus () {
      this.axios.get('api-company/menu').then((res) => {
        this.menus = res.data.data
      }
      )
    }
  }
}
</script>
