<template>
  <div class="flex items-center justify-center h-screen px-6 bg-gray-200">
    <div class="w-full max-w-sm p-6 bg-white rounded-md shadow-md">
      <div class="flex items-center justify-center">
        <img src="@/assets/images/icon.png" height="100" alt="tengy" />
        <span class="text-2xl font-semibold text-gray-700"
          >Tengy <br/> Company Portable</span
        >
      </div>

      <form class="mt-4" @submit.prevent="register">
        <label class="block">
          <span class="text-sm text-gray-700">Name</span>
          <input
          autocomplete="name"
            type="text"
            class="
              block
              w-full
              mt-1
              border-gray-200
              rounded-md
              focus:border-green-600
              focus:ring
              focus:ring-opacity-40
              focus:ring-green-500
            "
            v-model="user.name"
          />
        </label>
        <label class="block">
          <span class="text-sm text-gray-700">Surname</span>
          <input
          autocomplete="surname"
            type="text"
            class="
              block
              w-full
              mt-1
              border-gray-200
              rounded-md
              focus:border-green-600
              focus:ring
              focus:ring-opacity-40
              focus:ring-green-500
            "
            v-model="user.surname"
          />
        </label>
        <label class="block">
          <span class="text-sm text-gray-700">Company Name</span>
          <input
          autocomplete="company"
            type="text"
            class="
              block
              w-full
              mt-1
              border-gray-200
              rounded-md
              focus:border-green-600
              focus:ring
              focus:ring-opacity-40
              focus:ring-green-500
            "
            v-model="user.company"
          />
        </label>
        <label class="block">
          <span class="text-sm text-gray-700">Email</span>
          <input
          autocomplete="email"
            type="email"
            class="
              block
              w-full
              mt-1
              border-gray-200
              rounded-md
              focus:border-green-600
              focus:ring
              focus:ring-opacity-40
              focus:ring-green-500
            "
            v-model="user.email"
          />
        </label>
        <label class="block">
          <span class="text-sm text-gray-700">Phone Number</span>
          <input
          autocomplete="phone"
            type="text"
            class="
              block
              w-full
              mt-1
              border-gray-200
              rounded-md
              focus:border-green-600
              focus:ring
              focus:ring-opacity-40
              focus:ring-green-500
            "
            v-model="user.phone"
          />
        </label>

        <label class="block mt-3">
          <span class="text-sm text-gray-700" >Password</span>
          <input
          autocomplete="current-password"
            type="password"
            class="
              block
              w-full
              mt-1
              border-gray-200
              rounded-md
              focus:border-green-600
              focus:ring
              focus:ring-opacity-40
              focus:ring-green-500
            "
            v-model="user.password"
          />
        </label>

        <div class="flex items-center justify-between mt-4">
          <div>
            <label class="inline-flex items-center">
              <input
                type="checkbox"
                class="
                  text-green-600
                  border-gray-200
                  rounded-md
                  focus:border-green-600
                  focus:ring
                  focus:ring-opacity-40
                  focus:ring-green-500
                "
              />
              <span class="mx-2 text-sm text-gray-600">Terms and Conditions</span>
            </label>
          </div>
        </div>

        <div class="mt-6">
          <button
            @click="register()"
            type="button"
            class="
              w-full
              px-4
              py-2
              text-sm text-center text-white
              bg-green-600
              rounded-md
              focus:outline-none
              hover:bg-green-500
            "
          >
            Create Account
          </button>
        </div>

      </form>
                <div class="flex items-center justify-between mt-4">
            <a
              class="block text-sm text-green-700 fontme hover:underline"
              href="login"
              >I have an account already</a
            >
          </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    user: {
      username: '',
      password: '',
      remember: true
    }
  }),
  methods: {
    register () {
      this.$auth
        .register({
          url: 'api-auth/register/company',
          data: this.user,
          redirect: { name: 'dashboard' },
          fetchUser: false,
          staySignedIn: true
        })
        .then((res) => {
          if (this.user.remember) {
            // auth.remember(JSON.stringify({
            //   name: auth.user().first_name
            // }))
          }
          this.$auth.token(null, res.data.access_token, true)
        }, (res) => {
          // if (res.status === 400 || res.status === 401) {
          this.$toast.error('Invalid username or password')
        })
    }
  }
}
</script>
