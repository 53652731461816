<template>
  <div class="bg-gray-900 my-5">
    <div class="mx-auto max-w-7xl">
      <div class="grid grid-cols-1 gap-px bg-white/5 sm:grid-cols-2 lg:grid-cols-4">
        <div class="bg-gray-900 px-4 py-6 sm:px-6 lg:px-8">
          <p class="text-sm font-medium leading-6 text-gray-400">Total Sales</p>
          <p class="mt-2 flex items-baseline gap-x-2">
            <span class="text-4xl font-semibold tracking-tight text-white">{{ totalSales.toFixed(2) }}</span>
            <!-- <span class="text-sm text-gray-400">{{ totalSales }}</span> -->
          </p>
        </div>
        <div class="bg-gray-900 px-4 py-6 sm:px-6 lg:px-8">
          <p class="text-sm font-medium leading-6 text-gray-400">Total Orders</p>
          <p class="mt-2 flex items-baseline gap-x-2">
            <span class="text-4xl font-semibold tracking-tight text-white">{{ numberOfOrders }}</span>
            <!-- <span class="text-sm text-gray-400">{{ numberOfOrders }}</span> -->
          </p>
        </div>
        <div class="bg-gray-900 px-4 py-6 sm:px-6 lg:px-8">
          <p class="text-sm font-medium leading-6 text-gray-400">Total Items</p>
          <p class="mt-2 flex items-baseline gap-x-2">
            <span class="text-4xl font-semibold tracking-tight text-white">{{ numberOfItems }}</span>
            <!-- <span class="text-sm text-gray-400">{{ numberOfItems }}</span> -->
          </p>
        </div>
        <div class="bg-gray-900 px-4 py-6 sm:px-6 lg:px-8">
          <p class="text-sm font-medium leading-6 text-gray-400">Todays Total</p>
          <p class="mt-2 flex items-baseline gap-x-2">
            <span class="text-4xl font-semibold tracking-tight text-white">{{ todaysTotalSales }}</span>
            <span class="text-sm text-gray-400">Items: {{ todaysTotalItems }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div
    class="py-10 text-lg font-extrabold text-gray-900 rounded-md bg-gray-50 px-6 sm:flex sm:items-start sm:justify-between"
    v-if="isLoading">
    Loading...
  </div>
  <div class="bg-white shadow sm:rounded-lg" v-if="!isLoading">

    <template v-if="sales.length > 0">
      <div class="px-4 py-5 sm:p-6" :key="sale" v-for="sale in sales">
        <h3 class="text-base font-semibold leading-6 text-gray-900">Sale ID: {{ sale.id }}</h3>
        <div class="mt-5" v-if="sale.multi_order_orders.multi_order_payment_transaction != null">
          <div class="rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-start sm:justify-between">
            <h4 class="sr-only">Visa</h4>
            <div class="sm:flex sm:items-start">
              <svg class="h-8 w-auto sm:h-6 sm:flex-shrink-0" viewBox="0 0 36 24" aria-hidden="true">
                <rect width="36" height="24" fill="#224DBA" rx="4" />
                <path fill="#fff"
                  d="M10.925 15.673H8.874l-1.538-6c-.073-.276-.228-.52-.456-.635A6.575 6.575 0 005 8.403v-.231h3.304c.456 0 .798.347.855.75l.798 4.328 2.05-5.078h1.994l-3.076 7.5zm4.216 0h-1.937L14.8 8.172h1.937l-1.595 7.5zm4.101-5.422c.057-.404.399-.635.798-.635a3.54 3.54 0 011.88.346l.342-1.615A4.808 4.808 0 0020.496 8c-1.88 0-3.248 1.039-3.248 2.481 0 1.097.969 1.673 1.653 2.02.74.346 1.025.577.968.923 0 .519-.57.75-1.139.75a4.795 4.795 0 01-1.994-.462l-.342 1.616a5.48 5.48 0 002.108.404c2.108.057 3.418-.981 3.418-2.539 0-1.962-2.678-2.077-2.678-2.942zm9.457 5.422L27.16 8.172h-1.652a.858.858 0 00-.798.577l-2.848 6.924h1.994l.398-1.096h2.45l.228 1.096h1.766zm-2.905-5.482l.57 2.827h-1.596l1.026-2.827z" />
              </svg>
              <div class="mt-3 sm:ml-4 sm:mt-0">
                <div class="text-sm font-medium text-gray-900" v-if="sale.location">Vendor: {{ sale.location.name }}</div>
                <div class="text-sm font-medium text-gray-900">Order Number: {{ sale.uuid }}</div>
                <div class="mt-1 text-sm text-gray-600 sm:flex sm:items-center">
                  <div>Created At</div>
                  <span class="hidden sm:mx-2 sm:inline" aria-hidden="true">&middot;</span>
                  <div class="mt-1 sm:mt-0">{{ getDate(sale.created_at) }}</div>
                </div>
                <div class="mt-1 text-sm text-gray-600 sm:flex sm:items-center">
                  <div>Internal Order Id:</div>
                  <span class="hidden sm:mx-2 sm:inline" aria-hidden="true">&middot;</span>
                  <div class="mt-1 sm:mt-0">{{ sale.id }}</div>
                </div>
                <div class="mt-1 text-sm text-gray-600 sm:flex sm:items-center">
                  <div>Multi Order Total:</div>
                  <span class="hidden sm:mx-2 sm:inline" aria-hidden="true">&middot;</span>
                  <div class="mt-1 sm:mt-0">{{ sale.multi_order_orders.multi_order_payment_transaction.multi_order_amount
                  }}</div>
                </div>
                <div class="mt-1 text-sm text-gray-600 sm:flex sm:items-center">
                  <div>Multi Order Transaction Reference:</div>
                  <span class="hidden sm:mx-2 sm:inline" aria-hidden="true">&middot;</span>
                  <div class="mt-1 sm:mt-0">{{ sale.multi_order_orders.multi_order_payment_transaction.reference }}</div>
                </div>
                <div
                  v-if="sale.multi_order_orders && sale.multi_order_orders.multi_order_payment_transaction && sale.multi_order_orders.multi_order_payment_transaction.verification_data && sale.multi_order_orders.multi_order_payment_transaction.verification_data.message"
                  class="mt-1 text-sm text-gray-600 sm:flex sm:items-center px-2  bg-green-50">
                  <div>Transaction Verification Message:</div>
                  <span class="hidden sm:mx-2 sm:inline" aria-hidden="true">&middot;</span>
                  <div class="mt-1 sm:mt-0">{{
                    sale.multi_order_orders.multi_order_payment_transaction.verification_data.message }}</div>
                </div>
                <div v-else class="mt-1 text-sm text-gray-600 sm:flex sm:items-center px-2  bg-red-50">
                  <div>Transaction Verification Status:</div>
                  <span class="hidden sm:mx-2 sm:inline" aria-hidden="true">&middot;</span>
                  <div class="mt-1 sm:mt-0">Transaction not verified</div>
                </div>

                <div :id="'accordion-flush-' + sale.id"  data-accordion="collapse" data-active-classes="bg-white dark:bg-gray-900 text-gray-900 dark:text-white" data-inactive-classes="text-gray-500 dark:text-gray-400">
                  <h2 :id="'accordion-flush-heading-' + sale.id">
                    <button type="button" class="flex items-center justify-between w-full py-5 font-medium rtl:text-right text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400 gap-3" :data-accordion-target="'#accordion-flush-body-' + sale.id" aria-expanded="true" :aria-controls="'accordion-flush-body-' + sale.id">
                      <span>Order Items</span>
                      <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
                      </svg>
                    </button>
                  </h2>
                  <div :id="'accordion-flush-body-' + sale.id" class="" :aria-labelledby="'accordion-flush-heading-1' + sale.id">
                    <div class="py-5 border-b border-gray-200 dark:border-gray-700">
                      <template v-for="item in sale.order_items" :key="sale.id">
                        <p v-if="item.item" class="mb-2 text-gray-500 dark:text-gray-400" >
                          {{ item.item.name }} - {{ item.qty }} Qty x R{{ item.price }} = R{{ item.price * item.qty }}
                        </p>
                 </template>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="mt-4 sm:ml-6 sm:mt-0 sm:flex-shrink-0">

              Total: {{ getTotal(sale) }}
              <!-- <span v-if="sale.multi_order_orders.multi_order_payment_transaction">
              Verification: {{
                multi_order_orders.multi_order_payment_transaction.multi_order_amount }}
              </span> -->
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import moment from 'moment'
import initAccordions from 'flowbite'

export default {
  onMounted () {
    initAccordions()
  },
  data: () => {
    return {
      sales: [
        {
          multi_order_orders: {
            multi_order_payment_transaction: {
              response_data: {},
              verification_data: {}
            }
          },
          location: {},
          order_items: [
            {
              item: {}
            }
          ],
          order_status: {}
        }
      ],
      numberOfOrders: 0,
      numberOfItems: 0,
      totalSales: 0,
      todaysTotalSales: 0,
      todaysTotalItems: 0,
      isLoading: false
    }
  },

  mounted () {
    this.getSales()
  },

  methods: {

    getDate (date) {
      return moment(date).format('dddd, MMMM YYYY, h:mm:ss')
    },

    getSales () {
      this.isLoading = true
      this.axios.post('/api-company/reports/sales-report').then((response) => {
        this.sales = response.data
        this.sales.forEach((sale) => {
          if (sale.multi_order_orders.multi_order_payment_transaction == null) {
            return
          }
          const nowTotal = parseFloat(sale.multi_order_orders.multi_order_payment_transaction.multi_order_amount)
          this.totalSales += nowTotal
          this.numberOfOrders += 1
          sale.order_items.forEach((product) => {
            this.numberOfItems += product.qty
          })
          if (Date.now() - Date.parse(sale.created_at) < 86400000) {
            this.todaysTotalSales += nowTotal
            sale.order_items.forEach((product) => {
              this.todaysTotalItems += product.qty
            })
          }
        }
        )
        this.isLoading = false
      })
    },

    getTotal (sale) {
      let total = 0
      sale.order_items.forEach((product) => {
        total += product.price * product.qty
      })
      return total.toFixed(2)
    }
  }

}

</script>
